.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.detailsTabs {
  margin-top: 0px !important;
}

.p-splitter-gutter {
  height: 10px !important;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-float infinite 3s ease-in-out;
	}
}

.App-link {
	color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

.App {
  text-align: center;
  display: flex;
}
